


"use client"

import { APP_ROUTES } from "@/app/constants/app-router"
import { getUserTokens } from "@/app/functions/check-user-authenticated"
import { useRouter } from "next/navigation"
import { ReactNode, useEffect } from "react"

type PrivateRouteProps = {
    children: ReactNode
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
    const { push } = useRouter()

    const isUserAuthenticated = getUserTokens()

    useEffect(() => {
        if (!isUserAuthenticated) {
            push(APP_ROUTES.public.login)
        }
    }, [isUserAuthenticated, push])

    return (
        <>
        {!isUserAuthenticated && null}
        {isUserAuthenticated && children}
        </>
    )
}

export default PrivateRoute