"use client"
import type { Metadata } from "next";
import { Inter } from "next/font/google";
import "./globals.css";
import { usePathname, useRouter } from "next/navigation";

import { useEffect } from "react";
import { APP_ROUTES } from "./constants/app-router";
import PrivateRoute from "@/components/PrivateRoute";
import { checkIsPublicRoute } from "./functions/check-is-public-route";
import { getUserTokens } from "./functions/check-user-authenticated";
import formbricks from "@formbricks/js/website";
import Hotjar from '@hotjar/browser';




const inter = Inter({ subsets: ["latin"] });




export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {

  const router = useRouter();

  const pathname = usePathname();

  const isPublicPage = checkIsPublicRoute(pathname!);

  useEffect(() => {
    const tokens = getUserTokens();
    const isPrivateRoute = Object.values(APP_ROUTES.private).some(
      (route) => pathname === route.name
    );
    const isSignupPage = pathname === APP_ROUTES.public.register; 
    const targetPath = tokens
      ? isPrivateRoute
        ? pathname
        : APP_ROUTES.private.home.name
      : pathname; 
    if (pathname !== targetPath) {
      router.push(targetPath);
    }
  }, [router, pathname]);

  useEffect(() => {
    const siteId = 5025687;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);
  }, []);
  

  return (
    <html lang="pt-br">
      <body className={inter.className}>
      <link rel="shortcut icon" href="favicon.ico" type="image/x-icon" />
      <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
      <title>Vital</title>
      <meta name="description" content="Monitoramento em tempo real de cirurgias e dados vitais do paciente." />
     

      {isPublicPage && children}

      
      {!isPublicPage && <PrivateRoute>{children}</PrivateRoute>}
      </body>
    </html>
  );
}
