export const APP_ROUTES = {
    private: {
        home: {
            name: '/home'
        },
        profile: {
            name: '/profile'
        },
        register: {
            name: '/register'
        },
        confirm: {
            name: '/confirm'
        },
       
    },
    public: {
        login: '/',
        register: '/register',
        recover: '/recovery-password',
        new: "/new-password",
    }
}