import Cookie from 'js-cookie';

export const getUserTokens = () => {
  const accessToken = Cookie.get('accessToken');
  const refreshToken = Cookie.get('refreshToken');


  if (!accessToken || !refreshToken) {
    return null;
  }

  return {
    accessToken,
    refreshToken
  };
};